// Here you can add other styles
// Here you can add other styles
h4,
h5,
h6 {
  margin-bottom: 0;
}

.bg-dark {
  --cui-bg-opacity: 0;
  background-color: black !important;
}

.btn-dark {
  --cui-btn-bg: #000 !important;
  --cui-btn-border-color: #000 !important;
}

.nav {
  --cui-nav-link-padding-x: 1rem;
  --cui-nav-link-padding-y: 0.5rem;
  --cui-nav-link-font-weight: ;
  --cui-nav-link-color: var(--cui-link-color);
  --cui-nav-link-hover-color: var(--cui-link-hover-color);
  --cui-nav-link-disabled-color: #8a93a2;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-underline {
  border-bottom: 2px solid var(--cui-nav-underline-border-color, #C4C9D0);
}

.nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
  color: var(--cui-nav-underline-link-active-color, #000);
}

.nav-underline .nav-link.active,
.nav-underline .nav-link:focus,
.nav-underline .nav-link:hover,
.nav-underline .show>.nav-link {
  border-color: var(--cui-nav-underline-link-active-border-color, #000);
}

.nav-underline .nav-link {
  border-bottom: 2px solid transparent;
  border-bottom-color: transparent;
  color: var(--cui-nav-underline-link-color, #000);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  background-color: #9DA5B1;
  border-color: #9DA5B1;
}

.form-text {
  margin-top: 0.175rem;
  font-size: 1rem;
  color: #9da5b1;
}

.btn:hover {
  background-color: #525252;
  border-color: #525252;
}

.form-label {
  font-weight: 500;
  font-size: 18px;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--cui-input-focus-border-color, #B1B7C1);
}

.form-select:focus {
  box-shadow: none;
  border-color: var(--cui-input-focus-border-color, #B1B7C1);
}

.form-select-sm {
  height: 2.05rem;
  color: #959ba4;
}

.form-multi-select-sm>div[aria-expanded="false"] {
  max-height: 6rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.form-check-input {
  width: 1.5em;
  height: 1.5em;
  margin-top: 0em;
  margin-right: .5em;
}

.form-check-input:checked {
  background-color: var(--cui-form-check-input-checked-bg-color, #000);
  border-color: var(--cui-form-check-input-checked-border-color, #000);
}

.accordion-button:focus {
  box-shadow: none;
  border-color: var(--cui-input-focus-border-color, #B1B7C1);
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #E8E8E8;
  border-color: #E8E8E8;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: var(--cui-input-focus-border-color, #B1B7C1);
}

.picker-input-group.input-group-sm {
  height: 2.05rem;
}

.card-body {
  overflow: auto
}

.app-header-title {
  background-color: #E8E8E8;
  font-weight: 500;
}

.app-table-header {
  text-align: start;
}

.app-table-content-list {
  text-align: start;
  vertical-align: middle;
}

.table-badge-status-content {
  padding: 10px 20px;
  font-size: 12px;
}

.cell-table-out-of-stock {
  color: #9E9E9E;
}

.page-link.active,
.active>.page-link {
  background-color: #000;
  border-color: #000;
}

.dropdown-toggle-table-menu {
  border: none;
  box-shadow: none;
}

// custom text
.error-text-field {
  font-size: 14px;
  color: #E55353;
}

// upload image container
.upload_dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: border .24s ease-in-out;
  outline: 2px dashed #9E9E9E;
  border: 2px dashed #000;
  border-radius: .5rem;
}

.btn-upload {
  color: #FFF;
  background-color: #000;
  padding: 10px 15px;
  border-color: #000;
  border-radius: .5rem;
}

.btn-upload:hover,
.btn-upload:focus {
  background-color: #525252;
  border-color: #525252;
  color: #fff;
}

.upload_gallery {
  width: calc(33.333% - (2rem / 3));
  object-fit: contain;
}

// customer details
.customer-details-address-list {
  margin-bottom: 1rem;
  color: #000;
  padding: 8px 12px;
  border: 2px solid #E8E8E8;
  border-radius: .5rem;
}

.customer-details-main-shipping-address {
  margin-bottom: 0rem;
  font-style: italic;
  font-size: 14px;
  text-align: end;
}

.customer-details-tab-container {
  margin: .5rem;
  padding: .5rem;
  border-radius: .375rem;
  max-height: 39rem;
  overflow: auto;
}

// chat details
.chat_main_container {
  height: calc(100vh - 234px);
}

.chat_sidebar {
  width: 22rem;
  //   height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.chat_room {
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

.chat_room:hover {
  background-color: lightgrey;
}

.chat_room_active {
  background-color: lightskyblue;
}

.chat_room_info {
  color: black;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  min-width: 0;
  flex: 1;
}

.chat_room_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chat_room_content_preview {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chat_room_header_text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

// calendar details
.fc {
  --fc-event-bg-color: none !important;
  --fc-event-border-color: none !important;
  --fc-event-border-color: none !important;
}

.react-datepicker {
  font-size: 1rem !important;
  border: 1px solid #AEAEAE !important;
}

.react-datepicker__header {
  padding-top: 0.8rem !important;
}

.react-datepicker__month {
  margin: 1rem 2rem !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 2.75rem !important;
  line-height: 2.75rem !important;
  margin: 0.25rem !important;
}

.react-datepicker__current-month {
  font-size: 1.25rem !important;
  padding-bottom: 0.5rem !important;
}

.react-datepicker__navigation {
  top: 0.9rem !important;
}

.react-datepicker__navigation--previous {
  left: 1rem !important;
}

.react-datepicker__navigation--next {
  right: 1rem !important;
}

.react-datepicker__day--highlighted-custom-1 {
  color: white !important;
  background-color: #32BE3F !important;
  border-radius: 0.3rem !important;
}

.react-datepicker__day--highlighted-custom-2 {
  color: black !important;
  background-color: yellow !important;
  border-radius: 0.3rem !important;
}

.react-datepicker__today-button {
  padding: 0.5rem 0 !important;
}

.react-datepicker__month-select {
  padding: 0.3rem 0.3rem 0.2rem 0.3rem !important;
}

.react-datepicker__year-select {
  padding: 0.3rem 0.3rem 0.2rem 0.3rem !important;
}

.input-custom-time-picker {
  padding: 0.3rem 0.3rem 0.3rem 0.5rem !important;
  border: 1px solid #B1B7C1;
  border-radius: 0.25rem;
}

.calendar.days {
  border: 2px solid #e8e8e8;
  border-radius: 0.5rem;
}

.calendar-event-slot {
  display: flex;
  flex-direction: column;
  background-color: #E8E8E8;
  border-left: 3px solid black;
  border-radius: 0.25rem;
  padding: 0.1rem 0.5rem;
  overflow: auto;
}

.calendar-schedule {
  font-weight: bold;
}